// Bootstrap Skripte
// import 'bootstrap';
// import Alert from 'bootstrap/js/src/alert';
// import Button from 'bootstrap/js/src/button';
// import Carousel from 'bootstrap/js/src/carousel';
// import Collapse from 'bootstrap/js/src/collapse';
// import Dropdown from 'bootstrap/js/src/dropdown';
// import Modal from 'bootstrap/js/src/modal';
// import Popover from 'bootstrap/js/src/popover';
// import Scrollspy from 'bootstrap/js/src/scrollspy';
// import Tab from 'bootstrap/js/src/tab';
// import Toast from 'bootstrap/js/src/toast';
// import Tooltip from 'bootstrap/js/src/tooltip';

// Integration jQuery PushMenu
import pushMenu from  './js/_push-menu-init';
pushMenu();

// Integration jQuery MatchHeight
// import 'jquery-match-height';

// Integration Touch Device
// require('./js/components/touch-device');

// eigenes Contao Accordion Script, weitere Infos in der JS-Datei
require('./js/_accordion');

// Integration um eine Navi zu öffnen
// import toggleNavi from './js/components/toggle-navi'
// toggleNavi('meine-toggle-navi-klasse');

// JS für animiertes Upload-Feld in Formularen
// in package.json muss "bs-custom-file-input" bei den "dependencies" hinzugefügt werden
// import './js/components/form-upload';

// JS um zu Errorfeldern zu scrollen
// import scrollToError from  './js/components/scroll-to-error';
// scrollToError(100, 1000);

// My Styles
import './scss/styles.scss'
