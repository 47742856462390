require('./_push-menu');

import $ from 'jquery';

/* initialise jPushMenu */

export default function (selector) {
  $(document).ready(function () {
    $(selector || '.nav-main-toggler').jPushMenu({ closeOnClickOutside: false, closeOnClickLink: false });
  });
}
