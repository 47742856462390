// import jQuery from 'jquery';
// jQuery über das Contao Layout einbinden

// require('jquery-ui-bundle');
// jQueryUI über das Contao Template "templates/presets/j_query-ui.html5" einbinden

// .accordion-grouped
// Klasse an -mod_article und die Akkordeons arbeiten als Gruppe und das erste ist geöffnet
// zusätzlich .accordion-closed
// alle sind geschlossen
// zusätzlich .accordion-open-X (X = Nummer des Akkordeons - Start 0)
// das entsprechende Akkordeon ist geöffnet

// .accordion-single
// Klasse an -mod_article und die Akkordeons arbeiten einzeln und alle sind geschlossen
// .accordion-open an einem .ce_accordion öffnet das entsprechende Akkordeon

jQuery(function($) {
  const commonOpts = {
    // Put common options here
    heightStyle: 'content',
    header: '.toggler',
    collapsible: true,
    create: function(event, ui) {
      ui.header.addClass('active');
      $('.toggler').attr('tabindex', 0);
    },
    activate: function(event, ui) {
      ui.newHeader.addClass('active');
      ui.oldHeader.removeClass('active');
      $('.toggler').attr('tabindex', 0);
    }
  }

  $(".accordion-grouped").each(function() {
    const that = this;
    $(this).accordion(Object.assign({}, commonOpts, {
      // Put custom options here
      active: this.classList.contains('accordion-closed') ? false : (Array.from(Array(10).keys()).find(function (number) {
        return that.classList.contains('accordion-open-' + number);
      }) || 0),
    }));
  });
  $(".accordion-single .ce_accordion").each(function() {
    $(this).accordion(Object.assign({}, commonOpts, {
      // Put custom options here
      active: this.classList.contains('accordion-open') ? 0 : false
    }));
  });
});
